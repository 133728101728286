<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <UserForm :user="user" />
  </main>
</template>

<script>
import User from '../../../store/models/User';

import UserForm from '../../../components/admin/user/UserForm.vue';

export default {
  components: { UserForm },

  computed: {
    user() {
      return User.query().withAll().find(this.$route.params.id);
    },
  },

  metaInfo() {
    return { title: this.user && this.user.name };
  },

  beforeMount() {
    if (!this.user) {
      this.$store.dispatch('users/maybeFetch', {
        filter: { id: this.$route.params.id },
      });
    }

    this.$store.commit('info/setBreadcrumb', [
      { path: '/admin/user', label: 'User' },
    ]);
  },
};
</script>
